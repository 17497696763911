import React, { useState, useEffect } from 'react'
import './style.less'
import { RouteComponentProps, withRouter } from 'react-router'
import {
    Dimmer,
    Loader,
    Icon,
    Tab,
    Message,
    TabProps,
    PaginationProps,
    Modal,
    TransitionablePortal,
    Popup,
    Dropdown,
} from 'semantic-ui-react'
import { PersonOfTheMonth, User, UserProfile } from '../../../types'
import 'flatpickr/dist/themes/airbnb.css'
import 'rc-time-picker/assets/index.css'
import {
    newPersonOfTheMonth,
    pplOfTheMonths,
    deletePersonOfTheMonth,
    getUploadUrl,
    uploadToSignedUrl,
    checkDestPlayableWithTimeDelay,
    updatePersonOfTheMonth,
} from '../../../services/PplOfTheMonth'
import POTMForm from '../../../components/PplOfTheMonthForm'
import ListItemsPane from '../../../components/ListItemsPane'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import CalendarEventContent from '../../../components/CalendarEventContent'
import { jsonTocsvDownloader } from '../../../util/jsonTocsvDownloader';
import { toast } from 'react-toastify'
import { copySignageImageFromS3, uploadSignageImageToS3 } from '../../../services/ImageUpload';
import { prntyrLink } from '../../../util/data';
import { sortByKey } from '../../../util/sortData'
import { listRoomTypes } from '../../../services/RoomTypes'
import { getLocationId, sendToast } from '../../../util'
import { fetchOneUser, updateUser } from '../../../services/Users'
import { fetchOneFacility } from '../../../services/Facilities'
interface State {
    isFetching: boolean
    error: string | null
    isSaving: boolean
    residents: PersonOfTheMonth[] // activities: DailyActivity[];
    // activeActivities: DailyActivity[];
    // historyActivities: DailyActivity[];
    openCreateForm: boolean
    activityFilter: string
    activeTab: string
    activePageNumber: any
    copyActivity: PersonOfTheMonth | null
    activeTabIndex: number
    audioBlob
    selectedDateInCalendar: string | number
    selectedROMId?: string | undefined
    selectedROM?: PersonOfTheMonth | undefined
    copyItem?: any
    printBtnSpinner: boolean
    exportBtnSpinner: boolean
    copyImageUrl: string | undefined
    calendarTypeLoader: boolean,
    calendarType: string[],
    allCalendarType: {
        key: string;
        text: string;
        value: string;
    }[]
}

interface Props extends RouteComponentProps {
    profile: UserProfile | null
}

class ResidentOfTheMonth extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            isFetching: false,
            error: null,
            isSaving: false,
            residents: [],
            // activeActivities: [],
            // historyActivities: [],
            openCreateForm: false,
            activityFilter: '',
            activeTab: 'active',
            activePageNumber: 1,
            copyActivity: null,
            activeTabIndex: 0,
            audioBlob: undefined,
            selectedDateInCalendar: 0,
            selectedROM: undefined,
            selectedROMId: '',
            printBtnSpinner: false,
            exportBtnSpinner: false,
            copyImageUrl: undefined,
            calendarTypeLoader: false,
            calendarType: (this.props.profile && this.props.profile.selectedCalendarType && this.props.profile.selectedCalendarType.length && this.props.profile.selectedCalendarType) || [],
            allCalendarType: [],
        }
    }

    openCloseCreateActivityForm(): void {
        // if (this.state.copyActivity) {
        //     this.setState({
        //         openCreateForm: !this.state.openCreateForm,
        //         copyActivity: null
        //     });
        // } else {
        this.setState({
            openCreateForm: !this.state.openCreateForm,
            copyItem: undefined
        })
        // }
    }

    async deleteActivity(id): Promise<void> {
        this.setState({
            ...this.state,
            isSaving: true,
        })
        try {
            await deletePersonOfTheMonth({ _id: id }, 'resident')
            await this.populateStateWithPOTM('delete')
        } catch (error) {
            this.setState({
                ...this.state,
                isSaving: false,
                error: error,
            })
        }
    }

    editActivity(id: string): void {
        const residents = this.state.residents.map((activity: PersonOfTheMonth) => {
            if (activity._id === id) activity.edit = true
            return activity
        })
        this.setState({
            ...this.state,
            residents,
        })
    }

    cancelEditActivity(id?: string): void {
        if (id) {
            const residents = this.state.residents.map((activity: PersonOfTheMonth) => {
                if (activity._id === id) delete activity.edit
                return activity
            })
            this.setState({
                ...this.state,
                residents,
                copyActivity: null,
                selectedROM: undefined,
                selectedROMId: '',
                openCreateForm: false,
                audioBlob: null,
            })
        } else {
            this.setState({
                ...this.state,
                openCreateForm: false,
                selectedROM: undefined,
                selectedROMId: '',
                audioBlob: null,
            })
        }
    }

    copyActivity(id: string) {
        let rotm = this.state.residents.find(resident => resident._id === id);
        document.getElementsByClassName('page-header')[0].scrollIntoView({ block: 'start', behavior: 'smooth' });
        if (rotm) {
            this.setState({
                openCreateForm: true,
                // copyActivity: activity
                copyItem: rotm
            });
        }
    }

    passAudioBlob(audioBlob) {
        this.setState({
            audioBlob,
        })
    }

    async uploadAudiofile() {
        const { audioId = undefined, signedUrl = undefined } = await getUploadUrl('resident_of_the_month')

        const file = new File([this.state.audioBlob], audioId, {
            type: 'audio/wav',
        })

        await uploadToSignedUrl(file, signedUrl)

        return audioId
    }

    async uploadImageFile(ROTMID: string, imageFile: File): Promise<void> {
        if (imageFile) {
            await uploadSignageImageToS3("ROTM", ROTMID, imageFile.name, imageFile);
        } else {
            throw new Error("Image file is not provided");
        }
    }

    async copyUploadImageFile(ROTMID: string, copyUrl: string): Promise<void> {
        if (copyUrl) {
            await copySignageImageFromS3('ROTM', copyUrl, ROTMID);
        } else {
            throw new Error("url is not provided");
        }
    }


    async handleAudio(personOfTheMonth) {
        try {
            const audioId = await this.uploadAudiofile()

            if (audioId) {
                const destAudioId = audioId.replace(/\.wav$/, '.mp3')

                let destPlayableExists
                for (let i = 0; i < 6; i++) {
                    destPlayableExists = await checkDestPlayableWithTimeDelay(
                        destAudioId,
                        'resident_of_the_month',
                        Math.pow(2, i) * 1000,
                    )

                    if (destPlayableExists) {
                        break
                    }
                }

                if (destPlayableExists) {
                    console.log('audio submitting', {
                        name: personOfTheMonth.name,
                        startDate: personOfTheMonth.startDate,
                        endDate: personOfTheMonth.endDate,
                        AddedBy: this.props.profile ? this.props.profile._id : '',
                        audioId: destAudioId ? destAudioId : null,
                    })

                    try {
                        const ROTMID = await newPersonOfTheMonth('resident', {
                            name: personOfTheMonth.name,
                            startDate: personOfTheMonth.startDate,
                            endDate: personOfTheMonth.endDate,
                            AddedBy: this.props.profile ? this.props.profile._id : '',
                            audioId: destAudioId ? destAudioId : null,
                            calendarType: personOfTheMonth.calendarType,
                        })

                        if (ROTMID) {
                            if (personOfTheMonth.imageFile) {
                                await this.uploadImageFile(ROTMID, personOfTheMonth.imageFile);
                            } else if (personOfTheMonth.copyImageUrl) {
                                await this.copyUploadImageFile(ROTMID, personOfTheMonth.copyImageUrl);
                            } else if (this.state.copyImageUrl) {
                                await this.copyUploadImageFile(ROTMID, this.state.copyImageUrl);
                            }
                        }
                        await this.populateStateWithPOTM('save')
                    } catch (e) {
                        this.setState({
                            isSaving: false,
                            isFetching: false,
                            audioBlob: undefined,
                            error: e.message,
                            openCreateForm: false,
                        })
                    }
                } else {
                    this.setState({
                        isSaving: false,
                        isFetching: false,
                        audioBlob: undefined,
                        openCreateForm: false,
                        error: 'Failed to process audio. Please start over',
                    })
                }
            } else {
                this.setState({
                    isSaving: false,
                    isFetching: false,
                    audioBlob: undefined,
                    openCreateForm: false,
                    error: 'Error uploading audio. Please start over',
                })
            }
        } catch (e) {
            this.setState({
                isSaving: false,
                isFetching: false,
                audioBlob: undefined,
                error: e.message,
                openCreateForm: false,
            })
        }
        return false
    }

    async handleSubmit(personOfTheMonth: PersonOfTheMonth): Promise<void> {
        this.setState({
            isSaving: true,
            isFetching: true,
            error: null,
        })

        if (this.state.audioBlob) {
            try {
                await this.handleAudio.bind(this)(personOfTheMonth)
            } catch (e) {
                this.setState({
                    isSaving: false,
                    isFetching: false,
                    audioBlob: undefined,
                    error: e.message,
                    openCreateForm: false,
                })
            }
        } else {
            try {
                const ROTMID = await newPersonOfTheMonth('resident', {
                    name: personOfTheMonth.name,
                    startDate: personOfTheMonth.startDate,
                    endDate: personOfTheMonth.endDate,
                    AddedBy: this.props.profile ? this.props.profile._id : '',
                    audioId: personOfTheMonth.audioId,
                    playableUrl: personOfTheMonth.playableUrl,
                    calendarType: personOfTheMonth.calendarType
                })

                if (ROTMID) {
                    if (personOfTheMonth.imageFile) {
                        await this.uploadImageFile(ROTMID, personOfTheMonth.imageFile);
                    } else if (personOfTheMonth.copyImageUrl) {
                        await this.copyUploadImageFile(ROTMID, personOfTheMonth.copyImageUrl);
                    } else if (this.state.copyImageUrl) {
                        await this.copyUploadImageFile(ROTMID, this.state.copyImageUrl);
                    }
                }

                await this.populateStateWithPOTM('save')
            } catch (error) {
                this.setState({
                    isSaving: false,
                    isFetching: false,
                    error: error.message,
                    openCreateForm: false,
                })
            }
        }
    }

    async handleUpdate(personOfTheMonth: PersonOfTheMonth): Promise<void> {
        this.setState({
            isSaving: true,
            isFetching: true,
            error: null,
        })

        try {
            if (this.state.audioBlob) {
                try {
                    const audioId = await this.uploadAudiofile()

                    if (audioId) {
                        const destAudioId = audioId.replace(/\.wav$/, '.mp3')

                        let destPlayableExists
                        for (let i = 0; i < 6; i++) {
                            destPlayableExists = await checkDestPlayableWithTimeDelay(
                                destAudioId,
                                'resident_of_the_month',
                                Math.pow(2, i) * 1000,
                            )

                            if (destPlayableExists) {
                                break
                            }
                        }

                        if (destPlayableExists) {
                            try {
                                const res = await updatePersonOfTheMonth('resident', {
                                    _id: personOfTheMonth._id,
                                    name: personOfTheMonth.name,
                                    startDate: personOfTheMonth.startDate,
                                    endDate: personOfTheMonth.endDate,
                                    audioId: destAudioId ? destAudioId : null,
                                    calendarType: personOfTheMonth.calendarType
                                })

                                if (res && personOfTheMonth._id) {
                                    if (personOfTheMonth.imageFile) {
                                        await this.uploadImageFile(personOfTheMonth._id, personOfTheMonth.imageFile);
                                    } else if (personOfTheMonth.copyImageUrl) {
                                        await this.copyUploadImageFile(personOfTheMonth._id, personOfTheMonth.copyImageUrl);
                                    }
                                }
                            } catch (e) {
                                this.setState({
                                    openCreateForm: false,
                                    isSaving: false,
                                    isFetching: false,
                                    audioBlob: undefined,
                                    error: e.message,
                                })
                            }
                        } else {
                            this.setState({
                                isSaving: false,
                                isFetching: false,
                                audioBlob: undefined,
                                error: 'Failed to process audio. Please start over',
                            })
                        }
                    }
                } catch (e) {
                    console.log(e)
                }
            } else {
                const res = await updatePersonOfTheMonth('resident', {
                    _id: personOfTheMonth._id,
                    name: personOfTheMonth.name,
                    startDate: personOfTheMonth.startDate,
                    endDate: personOfTheMonth.endDate,
                    calendarType: personOfTheMonth.calendarType
                })
                if (res && personOfTheMonth._id) {
                    if (personOfTheMonth.imageFile) {
                        await this.uploadImageFile(personOfTheMonth._id, personOfTheMonth.imageFile);
                    } else if (personOfTheMonth.copyImageUrl) {
                        await this.copyUploadImageFile(personOfTheMonth._id, personOfTheMonth.copyImageUrl);
                    }
                }
            }

            await this.populateStateWithPOTM('update')
        } catch (e) {
            this.setState({
                isSaving: false,
                isFetching: false,
                error: e.message,
            })
        }
    }

    async handleEventClick(payload) {
        const selectedROM = this.state.residents.filter((event) => event._id === payload.event._def.publicId)[0]
        this.setState({
            ...this.state,
            selectedDateInCalendar: selectedROM.startDate,
            activityFilter: selectedROM.startDate,
            openCreateForm: true,
            selectedROMId: payload.event._def.publicId,
            selectedROM: selectedROM,
            error: '',
            copyItem: undefined
        })
    }

    async getResidentsOfTheMonths(filters = {}): Promise<PersonOfTheMonth[]> {
        this.setState({ isFetching: true, error: null })
        const activities = await pplOfTheMonths('resident', filters)
        return activities.Result
    }

    async setActivityDateFilter(time) {
        const date = new Date(Date.parse(time)).toLocaleDateString('en-US')
        const calendarType = this.state.calendarType && this.state.calendarType.length && this.state.calendarType
        const residents = await this.getResidentsOfTheMonths({
            ...(calendarType ? { calendarType: { $in: calendarType } } : {})
        })
        this.setState({
            activityFilter: date,
            residents: residents.filter(
                (activity: PersonOfTheMonth) => new Date(activity.startDate).toLocaleDateString('en-US') === date,
            ),
            activePageNumber: 1,
            isFetching: false,
        })
    }

    async clearDateFilter() {
        this.setState({
            ...this.state,
            activityFilter: '',
        })
        await this.populateStateWithPOTM('mount')
    }

    changePage(e, data: PaginationProps): void {
        this.setState({
            activePageNumber: data.activePage,
        })
    }

    getPaginatedActivity() {
        console.log('paginated activity')
        // if (this.state.activities.length > this.paginationPerPageNumber) {
        //     const startNumber = (this.state.activePageNumber - 1) * this.paginationPerPageNumber;
        //     const endNumber = (this.state.activePageNumber) * this.paginationPerPageNumber - 1;

        //     let returnActivities = this.state.activities;

        //     if (this.state.activeTab === 'history') {
        //         returnActivities = this.state.activities.slice();
        //         returnActivities.sort((a, b) => b.timestamp - a.timestamp);
        //     }

        //     return returnActivities.filter((activity: DailyActivity, index: number) => {
        //         return index >= startNumber && index <= endNumber;
        //     });
        // }
        return this.state.residents
    }

    async populateStateWithPOTM(readActivityIntent) {
        try {
            const calendarType = this.state.calendarType && this.state.calendarType.length && this.state.calendarType;
            const residentsOfTheMonths = await this.getResidentsOfTheMonths({
                ...(calendarType ? { calendarType: { $in: calendarType } } : {})
            })
            this.setState({
                ...this.state,
                isFetching: false,
                isSaving: false,
                openCreateForm: false,
                activeTab: 'active',
                residents: residentsOfTheMonths,
                // activities: activeActivities,
                // activeActivities,
                // historyActivities,
                activePageNumber: 1,
                activeTabIndex: 0,
                selectedDateInCalendar: 0,
                activityFilter: this.state.activityFilter,
                audioBlob: null,
                copyItem: undefined
            })
        } catch (error) {
            this.setState({
                ...this.state,
                isFetching: false,
                audioBlob: null,
                error,
            })
        }
    }

    async handleMonthChange(payload) {
        const calendarType = this.state.calendarType && this.state.calendarType.length && this.state.calendarType;
        const affirmations = await this.getResidentsOfTheMonths({
            startDate: {
                $gte: payload.start.toISOString().substring(0, 10),
                $lt: payload.end.toISOString().substring(0, 10),
            },
            ...(calendarType ? { calendarType: { $in: calendarType } } : {})
        })
        this.setState({
            ...this.state,
            residents: affirmations,
            isSaving: false,
            isFetching: false,
            openCreateForm: false,
        })
    }

    async handleDateClick(payload) {
        this.setState({
            ...this.state,
            selectedDateInCalendar: payload.date,
            openCreateForm: true,
            activityFilter: payload.date,
            copyItem: undefined
        })
    }

    async componentDidMount() {
        try {
            this.setState({
                calendarTypeLoader: true
            })
            const facilityId = this.props.profile && this.props.profile.Facility;
            const [facilityData, userData, roomTypes]: any[] = await Promise.all([
                facilityId ? fetchOneFacility(facilityId) : {},
                this.props.profile ? fetchOneUser(this.props.profile._id) : {},
                listRoomTypes()
            ]);
            if (roomTypes && roomTypes.Result && roomTypes.Result.length > 0) {
                const allCalendarType = roomTypes.Result.map(el => ({ key: el._id, value: el._id, text: el.Name }));
                const defaultLocationId = getLocationId('Other', allCalendarType);
                const defaultCalendarType = facilityData.defaultCalendarType ? [facilityData.defaultCalendarType] : [defaultLocationId];
                this.setState({
                    allCalendarType,
                    calendarType: (userData && userData.selectedCalendarType && userData.selectedCalendarType.length && userData.selectedCalendarType) || defaultCalendarType,
                });
            }
            await this.populateStateWithPOTM('mount')
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error while fetching room types.")
        } finally {
            this.setState({
                calendarTypeLoader: false
            })
        }
    }

    async handleTabChange(_: React.MouseEvent<HTMLDivElement, MouseEvent>, data: TabProps) {
        if (data.activeIndex === 1) {
            // await this.populateStateWithPOTM('mount')

            const date =
                this.state.activityFilter && new Date(Date.parse(this.state.activityFilter)).toLocaleDateString('en-US')
            this.setState({
                activityFilter: date,
                residents: this.state.activityFilter
                    ? this.state.residents.filter(
                        (activity: PersonOfTheMonth) =>
                            new Date(activity.startDate).toLocaleDateString('en-US') === date,
                    )
                    : this.state.residents,
                activePageNumber: 1,
            })
        }
    }
    // paginationPerPageNumber = 10;
    async handleCancel() {
        this.setState({
            ...this.state,
            openCreateForm: false,
            selectedROM: undefined,
            selectedROMId: '',
        })
    }
    async handleCalendarTypeChange(value: string[]) {
        try {
            this.setState({
                calendarTypeLoader: true,
                calendarType: value,
                isFetching: true
            });
            const staffUser = this.props.profile as unknown as User;
            const updatedStaff = {
                ...staffUser,
                selectedCalendarType: value || [],
                updateSelectedCalendarType: true
            };
            await updateUser(updatedStaff, staffUser);
            const residents = await this.getResidentsOfTheMonths({
                ...((value && value.length) ? { calendarType: { $in: value } } : {})
            })
            this.setState({
                residents,
            })
        } catch (error) {
            console.log('error in handleChange', error);
            toast.error(error instanceof Error ? error.message : "Error while fetching data.");
        } finally {
            this.setState({ calendarTypeLoader: false, isFetching: false });
        }
    }

    panes = [
        {
            menuItem: 'Calendar',
            render: () => {
                let exportCalendarData = async () => {
                    if (this.state.exportBtnSpinner) {
                        toast.warn("Task in progress, please wait until it's completed.", {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        })
                        return
                    }
                    this.setState({ exportBtnSpinner : true})
                    await jsonTocsvDownloader(this.state.residents, "ResidentOfTheMonth")
                    this.setState({ exportBtnSpinner: false })

                }

                return <div className="menu-calendar-view">
                    <FullCalendar
                        customButtons={{
                            addActivity : { 
                                text: `+`,
                                click: () => {
                                    this.openCloseCreateActivityForm()
                                }, 
                            },
                            export: {
                                text: this.state.exportBtnSpinner ? 'Loading...' : 'Export data',
                                click: function () {
                                    exportCalendarData();
                                }
                            }, 
                            print: {
                                text: this.state.printBtnSpinner ? 'Loading...' : 'Print Calendar',
                                click: async () => {
                                    window.open(prntyrLink, '_blank');
                                    // commented the print functionality as it is not required but we might need it in future
                                    // if (this.state.printBtnSpinner) {
                                    //     toast.warn("Task in progress, please wait until it's completed.", {
                                    //         position: 'bottom-center',
                                    //         autoClose: 5000,
                                    //         hideProgressBar: false,
                                    //         closeOnClick: true,
                                    //         pauseOnHover: true,
                                    //     })
                                    //     return
                                    // }
                                    // this.setState({ printBtnSpinner: true })
                                    // await jsonTocsvDownloader(this.state.residents, "ResidentOfTheMonth", true)
                                    // this.setState({ printBtnSpinner: false })
                                }
                            }
                        }}
                        headerToolbar={{
                            right: 'addActivity print export today prev,next'

                        }}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            timeZone="UTC"
                            initialView="dayGridMonth"
                            editable={true}
                            events={[
                                ...this.state.residents.map((event) => {
                                    return event.audioId ? {
                                        title: event.name ? event.name.replace(/(<([^>]+)>)/gi, '') : '',
                                        start: new Date(event.startDate),
                                        end: new Date(new Date(event.endDate).getTime() + 86400000),
                                        id: event._id,
                                        allDay: true,
                                        classNames:"audio"
                                    } : {
                                        title: event.name ? event.name.replace(/(<([^>]+)>)/gi, '') : '',
                                        start: new Date(event.startDate),
                                        end: new Date(new Date(event.endDate).getTime() + 86400000),
                                        id: event._id,
                                        allDay: true
                                    }
                                }),
                            ]}
                            datesSet={this.handleMonthChange.bind(this)}
                            dateClick={this.handleDateClick.bind(this)}
                            eventClick={this.handleEventClick.bind(this)}
                            eventContent={(info) => <CalendarEventContent info={info} />}
                        />
                    </div>
            }
        },
        {
            menuItem: 'List',
            render: () => (
                <ListItemsPane
                    loading={this.state.isFetching}
                    listItemFilter={this.state.activityFilter}
                    setListItemDateFilter={this.setActivityDateFilter.bind(this)}
                    isSaving={this.state.isSaving}
                    isFetching={this.state.isFetching}
                    clearDateFilter={this.clearDateFilter.bind(this)}
                    listItems={this.state.residents}
                    getPaginatedListItem={this.getPaginatedActivity.bind(this)}
                    deleteListItem={this.deleteActivity.bind(this)}
                    editListItem={this.editActivity.bind(this)}
                    copyListItem={this.copyActivity.bind(this)}
                    cancelEditListItem={this.cancelEditActivity.bind(this)}
                    handleSubmit={this.handleSubmit.bind(this)}
                    handleUpdate={this.handleUpdate.bind(this)}
                    sourcePage="ResidentOfTheMonth"
                    passAudioBlob={this.passAudioBlob.bind(this)}
                    allCalendarType={this.state.allCalendarType}
                    calendarType={this.state.calendarType}
                />
            ),
        },
    ]

    render() {
        const copyClickHandler = (copyImageUrl: string | undefined) => {
            let rotm = this.state.residents.find(resident => resident._id === this.state.selectedROMId);
            document.getElementsByClassName('page-header')[0].scrollIntoView({ block: 'start', behavior: 'smooth' });
            if (rotm) {
                this.setState({
                    openCreateForm: true,
                    copyItem: rotm,
                    selectedROMId: "",
                    copyImageUrl
                });
            }
        }
        return (
            <div className="DailyActivities">
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <div style={{ display: "flex", gap: "10px", alignItems: "flex-end" }}>
                    <Dropdown
                        loading={this.state.calendarTypeLoader}
                        selection={true}
                        value={this.state.calendarType}
                        options={sortByKey(this.state.allCalendarType)}
                        placeholder="Select Room Type"
                        style={{ width: "40%" }}
                        multiple
                        onChange={(e, { value }) => {
                            this.handleCalendarTypeChange(value as [])
                        }}
                    />
                </div>
                <>
                    <TransitionablePortal
                        open={this.state.openCreateForm}
                        transition={{ animation: 'fade down', duration: 500 }}
                        closeOnDocumentClick={false}
                    >
                        <Modal open={this.state.openCreateForm} size="tiny" className="edit-item-form" onClose={this.handleCancel.bind(this)}>
                            {this.state.selectedROMId ? (
                                <POTMForm
                                    isFetching={this.state.isFetching}
                                    isSaving={this.state.isSaving}
                                    handleSubmit={this.handleSubmit.bind(this)}
                                    sourcePage="ResidentOfTheMonth"
                                    passAudioBlob={this.passAudioBlob.bind(this)}
                                    startDate={
                                        this.state.selectedROM && this.state.selectedROM.startDate
                                            ? this.state.selectedROM.startDate
                                            : ''
                                    }
                                    endDate={
                                        this.state.selectedROM && this.state.selectedROM.endDate
                                            ? this.state.selectedROM.endDate
                                            : ''
                                    }
                                    handleCancel={this.handleCancel.bind(this)}
                                    handleUpdate={this.handleUpdate.bind(this)}
                                    edit={this.state.selectedROMId ? true : false}
                                    _id={this.state.selectedROMId}
                                    editableActivity={this.state.selectedROM}
                                    cancelEditActivity={this.cancelEditActivity.bind(this)}
                                    copyItem={this.state.copyItem}
                                    copyClickHandler={copyClickHandler}
                                    allCalendarType={this.state.allCalendarType}
                                    calendarType={this.state.calendarType}
                                />
                            ) : (
                                <POTMForm
                                    isFetching={this.state.isFetching}
                                    isSaving={this.state.isSaving}
                                    handleSubmit={this.handleSubmit.bind(this)}
                                    sourcePage="ResidentOfTheMonth"
                                    passAudioBlob={this.passAudioBlob.bind(this)}
                                    startDate={
                                        this.state.copyItem
                                            ? new Date(this.state.copyItem.startDate).toISOString().substring(0, 10)
                                            :
                                            (this.state.selectedDateInCalendar
                                                ? new Date(this.state.selectedDateInCalendar).toISOString().substring(0, 10)
                                                : ''
                                            )
                                    }
                                    endDate={
                                        this.state.copyItem
                                            ? new Date(this.state.copyItem.endDate).toISOString().substring(0, 10)
                                            :
                                            (this.state.selectedDateInCalendar
                                                ? new Date(this.state.selectedDateInCalendar).toISOString().substring(0, 10)
                                                : ''
                                            )
                                    }
                                    handleCancel={this.handleCancel.bind(this)}
                                    handleUpdate={this.handleUpdate.bind(this)}
                                    cancelEditActivity={this.cancelEditActivity.bind(this)}
                                    copyItem={this.state.copyItem}
                                    allCalendarType={this.state.allCalendarType}
                                    calendarType={this.state.calendarType}
                                />
                            )}
                        </Modal>
                    </TransitionablePortal>

                    {this.state.error && <Message negative>{this.state.error}</Message>}
                    <Tab
                        className="views-tab"
                        style = {{margin: 0}}
                        menu={{ secondary: true, pointing: true }}
                        panes={this.panes}
                        onTabChange={this.handleTabChange.bind(this)}
                    />
                </>
            </div>
        )
    }
}

export default withRouter(ResidentOfTheMonth)
