import { toast } from "react-toastify"
import { fetchOneFacility } from "../services/Facilities"
import { Facility } from "../types"
import { TypeDictionary } from "../services/Basics";
import Promise from 'bluebird';

export function urlB64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

export function originRedirect(): string { //setting the Origin hostname for Amplify
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    const protocol = isLocalhost ? 'http://' : 'https://';
    const hostname = window.location.hostname;
    const port = isLocalhost ? ':3000' : '';
    return `${protocol}${hostname}${port}`
}

export const sendToast = (type: "warn" | "success" | "error", message: string) => {
    if (type === 'success') {
        toast.success(message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        })
    } else if (type === 'warn') {
        toast.warn(message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        })
    } else if (type === 'error') {
        toast.error(message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        })
    }
}

export const sortKanbanList = (value: string, controlledBoard: any) => {
    if (value === 'FirstName') {
        controlledBoard.columns.forEach((item: any) => {
            item.cards.sort((a: any, b: any) => {
                const aFirstName = a.registrant && a.registrant.FirstName ? a.registrant.FirstName : '';
                const bFirstName = b.registrant && b.registrant.FirstName ? b.registrant.FirstName : '';
                return aFirstName < bFirstName ? -1 : aFirstName > bFirstName ? 1 : 0;
            });
        });
    } else if (value === 'LastName') {
        controlledBoard.columns.forEach((item: any) => {
            item.cards.sort((a: any, b: any) => {
                const aLastName = a.registrant && a.registrant.LastName ? a.registrant.LastName : '';
                const bLastName = b.registrant && b.registrant.LastName ? b.registrant.LastName : '';
                return aLastName < bLastName ? -1 : aLastName > bLastName ? 1 : 0;
            });
        });
    } else if (value === 'RoomName') {
        controlledBoard.columns.forEach((item: any) => {
            const hasRoomName = item.cards.filter((taskCard: any) => taskCard.roomName !== undefined);
            const missingRoomName = item.cards.filter((taskCard: any) => taskCard.roomName === undefined);
            hasRoomName.sort((a: any, b: any) => a.roomName.localeCompare(b.roomName, 'en', { numeric: true }));
            item.cards = [...hasRoomName, ...missingRoomName];
        });
    }
};

export const fetchAllPaginatedData = async (apiCall: any, params: any, sorting: any = undefined, page_size = 500) => {
    const totalData: any[] = [];
    let currentResponse: any[] = [];
    let TotRecords = 0;
    const pagingSorting: any = {
        page_size,
        page_no: 1,
    };
    if (sorting && Array.isArray(sorting) && (sorting.length > 0)) {
        pagingSorting.sort = sorting
    }
    do {
        const response = await apiCall({...params, pagingSorting});
        TotRecords = response.TotRecords;
        currentResponse = response.Result;
        totalData.push(...response.Result);
        pagingSorting.page_no++;
    } while (totalData.length < TotRecords && currentResponse.length > 0);
    return totalData;
}

export const dbIntensivePaginationWrapper = async ({
    apiCall,
    params,
    sorting,
    page_size = 500,
}:{
    apiCall: (params: any) => Promise<any>,
    params: any,
    sorting?: any,
    page_size?: number,
}) => {
    // Do one api call and get tot records
    // Generate an array of api calls based on tot records and page size
    // Run all api calls in parallel with promise.map concurrency
    const totalData: any[] = [];
    const payload = {...params, pagingSorting: {page_size, page_no: 1}}
    if (sorting && Array.isArray(sorting) && (sorting.length > 0)) {
        payload.pagingSorting.sort = sorting
    }
    const response = await apiCall(payload);
    totalData.push(...response.Result);
    const TotRecords = response.TotRecords;
    const totalPages = Math.ceil(TotRecords / page_size);
    const pageNos = Array.from({length: totalPages}, (_, i) => i + 1);
    await Promise.map(pageNos, async (page_no: number) => {
        const copyOfPayload = JSON.parse(JSON.stringify(payload));
        copyOfPayload.pagingSorting.page_no = page_no;
        const response = await apiCall(copyOfPayload);
        totalData.push(...response.Result);
    }, { concurrency: 5 });
    return totalData;
}

export const fetchFacilityTimezoneWithFacilityId = async (facilityId: string) => {
    if (!facilityId) throw new Error('Facility Id is required');
    const facilityData: Facility = await fetchOneFacility(facilityId);
    if (!facilityData) throw new Error('Facility not found'); 
    const FacilityTimeZone = facilityData.FacilityTimeZone;
    if (!FacilityTimeZone) throw new Error('Facility Timezone not found');
    return FacilityTimeZone as string;
}

export const formatFileName = (fileName: string) => {
    // Escape s3 unfriendly characters
    // but preserve file extension (.jpg, .png)
    const fileNameArray = fileName.split('.');
    const ext = fileNameArray.pop();
    const rawFileName = fileNameArray.join('');
    const URLSafeFileName = rawFileName.replace(/[^a-zA-Z0-9]/g, ''); //strip url unsafe elements
    return URLSafeFileName + '.' + ext; // file name + extension
};
// Mealtypes will be used to display the meal in service instances of menu category.
export const mealTypes = ['Breakfast', 'Brunch', 'Lunch', 'Snack', 'Dinner', 'All Day', 'Any'];

export const cookbook = 'cookbook'

/**
* Function to find the key of the dictionary where the given value is present in its array
* @param {string} value - The value to find in the arrays
* @returns {string|null} - The key where the value is found, or null if not found
*/
export const findKeyByValue = (value: string) => {
    for (const key in TypeDictionary) {
        if (TypeDictionary[key].includes(value)) {
            return key;
        }
    }
    return null;
};

export const getLocationId = (location: string, locationOptions: any[]) => {
    const foundObject = locationOptions.find((option) => option.text === location);
    return foundObject ? foundObject.value : '';
}